import React from "react"
import { Container, Row, Col, Breadcrumb } from 'react-bootstrap'
import { Section, MarkdownContent } from '../components/Section'
import Layout from "../components/layout"
import SEO from "../components/seo"
import PatternImg from "../images/line-pattern.png"
import RequestForm from "../components/RequestForm"
import '@material/react-select/dist/select.css';
import StickyBox from "react-sticky-box";

class SitePreparation extends React.Component {  
  render() {
    const { location } = this.props;
    const formId = "Request a Quote";
    return (
      <Layout location = { location }>
        <SEO title="Site Preparation" />
        <section style={{ background: `url(${PatternImg})` }}>
          <Container className='position-relative'>
            <Breadcrumb className='beadcrumbs-outer'>
              <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
              <Breadcrumb.Item active>Site Preparation</Breadcrumb.Item>
            </Breadcrumb>
          </Container>
        </section>
        <Section className='section' pt='100px' pb='100px'>
          <Container>
            <Row>
              <Col lg={7} xl={8}>
                <MarkdownContent>
                  <span>Requirements Info</span>
                  <h1>The Requirements You Need to Know About Before Your Metal Building Installation</h1>
                  <p>You’ve never been more ready to install your new metal building, right? After careful consideration, customization, and a unique order placed for your perfect metal building, it’s almost time for installation day. There’s just one problem—you’re not quite ready yet! How exactly do you prepare your site for your new metal building? We’ve laid out the perfect, 101-guide right here to guide you through the must-know steps for preparing your site for your new metal building!</p>

                  <h2>It Takes a Quality Beginning!</h2>
                  <p>At Carports R Us, we take pride in our role in manufacturing the best metal buildings in the business. We’re all about quality materials, the best lead times, professional installation, and great customer service (not to mention the fact that we put a huge emphasis on customization and unique orders)!</p>
                  <p>But, at the end of the day, a fantastic metal building on your site starts from the very beginning—and that means with your site’s preparedness.</p>
                  <p>If you’re looking for a quality outcome, we’re here to do our part—but have you done yours? A well-prepared site begins with the steps taken before we ever install your customized metal building.</p>
                  <p>How can you do your part to ensure that your site is ready to go? We’ve got the inside scoop here! Check out our requirements, checkpoints, and more below.</p>

                  <h2>Did you Check With Your County Regarding Permits and Codes?</h2>
                  <p>First things first, you’ll need to check in with your county about permits you might need or codes that might stand in your way. Your local building department should have all the facts and figures on what you need to know. We can’t stress this enough—you must check in about permits, codes, and regulations no matter why you’re adding on this metal building, residential or commercial.</p>
                  <p>These permits and codes are based on factors like your location and your weather conditions, which is why it’s vital that you check in with your local building department to get their A-OK. If you don’t, they likely have the right to take the structure down (if it doesn’t have proper permits or meet specific codes), and you’ll be stuck starting from scratch.</p>
                  <p>Guarantee a quality beginning for your metal building by checking this off your list ASAP.</p>

                  <h2>What’s Your Location?</h2>
                  <p>Consider where you’re planning to place your new metal building, carport, RV garage, boat cover, multi-purpose storage unit, or more. Ask yourself the important question: is there enough space here for this building? What’s my foundation like—will it support something this big? Is this a good, reliable, and durable spot for the long-term placement of this metal building? Ensure that you’re starting with a quality beginning when it comes to your designated location. The last thing you want to happen during your installation process is to realize your desired location isn’t a great choice. If this happens, you’ll likely stall your installation further or you’ll be stuck with a poorly fit metal building.</p>
                </MarkdownContent>
              </Col>
              <Col lg={5} xl={4}>
                <StickyBox offsetTop={170}>
                  <div className="form-wrapper">
                    <h2 className='form-title'>Request a Quote</h2>                    
                    <RequestForm location = { location } formId = { formId } />
                    </div>
                </StickyBox>
              </Col>
            </Row>
          </Container>
        </Section>
        <Section className='section' pt='100px' pb='100px' bg="#f8f8f8">
          <Container>
            <MarkdownContent>
              <h2>There is More to Metal Building Site Preparation!</h2>
              <p>But that’s not all! As important as checking for permits and picking the best possible spot for your new metal building might be, there’s still plenty of work to be done before you can consider your site ready for metal building installation. Don’t forget to do your primary groundwork, choose a proper foundation, clear the area of barriers, and more before you set-up your installation with your metal building team at Carports R Us.</p>
              <h3>Groundwork Preparation is a Must</h3>
              <p>First, make sure that you’re clearing your designated spot of all the debris in the area. Vegetation, trash, and other barriers need to be cleared out, the ground must be leveled before the installation process begins. The general rule of thumb is, if it looks like it’ll be in the way of the delivery truck or the installation crew, it probably will be. Proper ground preparation is vital!</p>
              
              <h3>Choosing a Proper Foundation is Key</h3>
              <p>Again, where you want to place your metal building matters! There are several types of foundation out there—concrete, asphalt, ground, gravel, etc. Make sure you’re choosing the type that makes the most sense for your metal building and your purpose. The foundation you select should depend on the area, the weather conditions you expect, and the type of metal building you’re hoping to install.</p>
              
              <h3>All Barriers and Barricades Must Be Removed</h3>
              <p>If the installation crew arrives with your brand-new metal building and there are barriers or barricades blocking them from doing their job, they’ll likely have to go through the effort to remove those barriers. Ultimately, they’ll need to charge for their labor, which will make the installation process more expensive. In the worst scenario, they might even have to leave the site and reschedule a return date for when barriers and barricades are removed (which could result in a return fee). Take care of this before they arrive to ensure a smooth installation.</p>
              
              <h3>What’s Next?</h3>
              <p>Once you’ve taken care of all the groundwork, the foundation is ready, and the barriers have been removed, contact the building specialists at Carports R Us to arrange your installation! We’ll coordinate with you on an ideal installation schedule with the proper lead time, and your metal building will be installed seamlessly!</p>
            </MarkdownContent>
          </Container>
        </Section>

      </Layout>
    )
  }
}

export default SitePreparation
